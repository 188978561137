<template>
  <b-card title="Create User">
    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-button variant="primary" :to="{ name: 'user-management' }">
            Back to List User
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <validation-observer ref="createAdmin">
            <b-form-group>
              <span>Username <span style="color:red">(*)</span></span>
              <validation-provider
                v-slot="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  v-model="userForm.name"
                  type="text"
                  placeholder="Username"
                  :state="errors.length > 0 ? false : null"
                  style="margin-top:5px !important;"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <span>Email <span style="color:red">(*)</span></span>
              <validation-provider
                name="Email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="userForm.email"
                  type="email"
                  placeholder="Email"
                  :state="errors.length > 0 ? false : null"
                  style="margin-top:5px !important;"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <span>Password <span style="color:red">(*)</span></span>
              <validation-provider
                name="Password"
                rules="required"
                v-slot="{ errors }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    v-model="userForm.password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    placeholder="Password"
                    :state="errors.length > 0 ? false : null"
                    @input="checkPasswordStrength"
                  ></b-form-input>
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <span
                v-if="userForm.password.length < 12 && userForm.password !== ''"
                style="color:red"
                >Your password must be at least 12 characters.</span
              >
            </b-form-group>
            <b-form-group>
              <b-row>
                <b-col cols="2">
                  Password Strength
                </b-col>
                <b-col cols="4">
                  <div style="display:flex" v-if="strengthResult[0] === 'Weak'">
                    <div class="bar-red">
                      Weak
                    </div>

                    <div class="bar-off"></div>

                    <div class="bar-off"></div>
                  </div>

                  <div
                    style="display:flex"
                    v-if="strengthResult[0] === 'Medium'"
                  >
                    <div class="bar-orange"></div>

                    <div class="bar-orange">
                      Medium
                    </div>

                    <div class="bar-off"></div>
                  </div>

                  <div
                    style="display:flex"
                    v-if="strengthResult[0] === 'Strong'"
                  >
                    <div class="bar-green"></div>

                    <div class="bar-green"></div>

                    <div class="bar-green">
                      Strong
                    </div>
                  </div>
                </b-col>
                <b-col cols="6">
                  <b-row>
                    <b-col cols="3">
                      <div
                        class="character-on"
                        v-if="strengthResult[1].includes('Lower Case')"
                      >
                        Lower Case
                      </div>
                      <div class="character-off" v-else>Lower Case</div>
                    </b-col>

                    <b-col cols="3">
                      <div
                        class="character-on"
                        v-if="strengthResult[1].includes('Upper Case')"
                      >
                        Upper Case
                      </div>
                      <div class="character-off" v-else>Upper Case</div>
                    </b-col>

                    <b-col cols="3">
                      <div
                        class="character-on"
                        v-if="strengthResult[1].includes('Number')"
                      >
                        Number
                      </div>
                      <div class="character-off" v-else>Number</div>
                    </b-col>

                    <b-col cols="3">
                      <div
                        class="character-on"
                        v-if="strengthResult[1].includes('Symbol')"
                      >
                        Symbol
                      </div>
                      <div class="character-off" v-else>Symbol</div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <span>Role <span style="color:red">(*)</span></span>
              <validation-provider
                name="Role"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="userForm.roleRef"
                  placeholder="----- Select Role -----"
                  :options="roles"
                  label="label"
                  :clearable="false"
                  :state="errors.length > 0 ? false : null"
                  style="margin-top:5px !important;"
                  class="mb-1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!--temporary hardcode only show this form select when roleRef.name is Dealer-->
            <b-form-group
              label="Dealer"
              v-if="
                permissionAllPO == false && userForm.roleRef.name === 'Dealer'
              "
            >
              <v-select
                v-model="dealer"
                placeholder="----- Select Dealer -----"
                :options="allDealers"
                label="dealerName"
                :clearable="true"
                class="mb-1"
              />
            </b-form-group>
            <b-button variant="success" @click="save">
              Save
            </b-button>
          </validation-observer>
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import dealerVue from "../dealer/dealer.vue";
import { sleep } from "@/utils/utils.js";

export default {
  components: {
    "validation-observer": ValidationObserver,
    "validation-provider": ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      dealer: "",
      strength: "",
      characters: "",
      userForm: {
        name: "",
        email: "",
        password: "",
        roleRef: "",
        app: "DRCC",
        dealer: "",
      },
    };
  },
  watch: {},
  methods: {
    checkPasswordStrength() {
      axios
        .post("/auth/password-strength/", {
          password: this.userForm.password,
        })
        .then((res) => {
          let enthropy = res.data.enthropy;
          this.strength = res.data.strength;
          this.characters = res.data.characters;
          console.log(this.strength);
        })
        .catch((err) => console.log({ err }));
    },
    save() {
      this.userForm.dealer = this.dealer;
      let forSave = this.userForm;

      if (this.strength == "Weak") {
        this.$bvToast.toast(`Password is too weak`, {
          title: "Password too weak",
          variant: "danger",
          solid: true,
        });
        return false;
      }

      if (forSave.dealer !== "") {
        forSave.dealer = forSave.dealer._id;
      } else {
        forSave.dealer = null;
      }
      this.$refs.createAdmin.validate().then((result) => {
        if (result) {
          this.registerUser(forSave)
            .then(async (response) => {
              this.$bvToast.toast(
                `Successfully created a user ${this.userForm.name}`,
                {
                  title: "Success",
                  variant: "success",
                  solid: true,
                }
              );
              //this is for save user id to dealer
              let { id, dealer } = response;
              if (dealer !== "" && dealer !== null && dealer !== undefined) {
                // console.log("id ", id);
                // console.log("dealer ", dealer);
                // let searchDealer = this.allDealers.find((x) => {
                //   return x._id === dealer;
                // });
                // console.log("searchDealer ", searchDealer);
                // searchDealer.users = [id, ...searchDealer.users];
                this.updateDealerV2({
                  userID: id,
                  dealerID: forSave.dealer._id,
                });
              }

              console.info("calling sleep");
              await sleep(1000);
              console.info("going back to list");
              this.$router.go(-1);
            })
            .catch((e) => {
              console.log({ e });
              this.$bvToast.toast(e.response.data.errors[0].message, {
                title: "Fail",
                variant: "danger",
                solid: true,
              });
            });

          this.loadDealer();
        }
      });
    },
    loadDealer() {
      //for UI display reload model userForm.dealer
      if (this.userForm.dealer) {
        var searchDealer = this.allDealers.find((e) => {
          return e._id === this.userForm.dealer;
        });
        this.userForm.dealer = Object.assign(searchDealer);
      }
      return this.userForm.dealer;
    },
    ...mapActions({
      registerUser: "auth/newUser",
      updateDealer: "dealer/updateDealer",
      updateDealerV2: "dealer/updateDealerV2",
    }),
  },
  computed: {
    strengthResult() {
      return [this.strength, this.characters];
    },
    roles() {
      var getRoles = this.$store.getters["role/getRole"]
        ? this.$store.getters["role/getRole"]
            .filter((el) => {
              return el.name !== "Super Admin" || el.level !== "Super Admin";
            })
            .map((el) => {
              // console.log
              return {
                ...el,
                label: `${el.name} - ${el.level}`,
              };
            })
        : [];
      return getRoles;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    allDealers() {
      var tempDealers = this.$store.getters["dealer/getDealers"].filter(
        (x) => x.status === ""
      );
      return tempDealers;
    },
    permissionAllPO() {
      //this is filter depend selected role. get all permission, and search permission role for purchase order menu
      var accessAllPO = false;
      if (this.userForm.roleRef.permission) {
        var tempPermission = this.userForm.roleRef.permission;
        var searchOrder = tempPermission.order_menu.find((e) => {
          return e.name === "Order";
        });
        accessAllPO = searchOrder.all;
      }
      return accessAllPO;
    },
  },
  mounted() {
    // console.log('id',this.id)
    this.$store.dispatch("dealer/getAllDealers");

    this.$store.dispatch("role/fetchRole").catch((err) => console.log(err));
  },
  created() {
    document.title = "User | RSP";
  },
};
</script>

<style scoped>
.character-off {
  text-align: center;
  background: #f6f6f6;
  padding: 9px 0;
  color: gray;
  border-radius: 10px;
}

.character-on {
  text-align: center;
  background: #0892d0;
  padding: 9px 0;
  color: white;
  border-radius: 10px;
}

.bar-off {
  margin: 3px;
  text-align: center;
  background: #f6f6f6;
  padding: 3px 0;
  color: white;
  border-radius: 3px;
  width: 33%;
}

.bar-red {
  margin: 3px;
  text-align: center;
  background: #ea5455;
  padding: 3px 0;
  color: white;
  border-radius: 3px;
  width: 33%;
}

.bar-orange {
  margin: 3px;
  text-align: center;
  background: #ff9f43;
  padding: 3px 0;
  color: white;
  border-radius: 3px;
  width: 33%;
}

.bar-green {
  margin: 3px;
  text-align: center;
  background: #28c76f;
  padding: 3px 0;
  color: white;
  border-radius: 3px;
  width: 33%;
}
</style>
